import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const teamCarousels = document.querySelectorAll('.block-carrousel-team');

  if (0 < teamCarousels.length) {
    teamCarousels.forEach(function (teamCarousel) {
      const swiperConfig = {
        draggable: true,
        loop: false,
        slidesPerView: 1,
        spaceBetween: 30,
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };

      swiperConfig.breakpoints = {
        600: {
          slidesPerView: 2,
        },
        769: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      };

      new Swiper(teamCarousel.querySelector('.team-swiper'), swiperConfig);
    });
  }
});
